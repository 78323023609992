// import style from "./sass/hotel-datepicker.css"
import style from "./css/DatePickerComponent.css"
import { useEffect } from "react";
import * as datedreamer from "datedreamer";
export function  DatePickerComponent({setdatetimeReport}){

       useEffect(()=>{
        new datedreamer.range({
            element:"#datepicker",
            hideInputs: false,
            darkMode: true,
            theme: 'lite-purple',
            format: "MM/DD/YYYY",
            onChange: (e) => {
                setdatetimeReport(e.detail)
              }

       })
    //    makeEventpositionateDatepicker({
    //     element:".datedreamer-range",
    //     position:"Bottom"
    //    })
    
    },[])
    return <div className="date_picker_compooent" style={style}>
        <div className="date-picker-btn" onClick={()=>{
                document.querySelector("#datepicker").classList.toggle("hide-date-picker")
        }}  >
            Fecha
        </div>
        <div id="datepicker" className="hide-date-picker" onClick={()=>{}}/>
    </div>
}


// function makeEventpositionateDatepicker(configs){

//     const element = document.querySelector(configs.element)
//     window.addEventListener("resize", ()=>{
//     console.log(element.offsetLeft);

//     });


// }