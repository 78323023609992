import React from "react";
import ReactDOM from "react-dom/client";
import {Login} from "./view/Login"
import {Admin} from "./view/Admin"
import {RegisterUser} from "./view/RegisterUser"
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Principal from "./view/Principal";
import Main from "./view/Main.jsx";
import DeleteUser from "./view/DeleteUser.jsx"
import {PrincipalPage} from "./view/PrincipalPage/PrincipalPage.jsx"
import {Rescue_account} from "./view/rescue_accoun.jsx"
import { HomeTemplate } from "./view/HomeTemplate.jsx";
import { ViewReportesSupervisor } from "./view/ViewReportesSupervisor.jsx";
import DeleteSOSUser from "./view/DeleteSOSUser/DeleteSOSUser.jsx"
const router = createBrowserRouter([
  {
    path: "/",
    element: <PrincipalPage/>,
  },
  {
    path: "/login",
    element: <Login/>,
  },
  {
    path:"/main",
    element:<Main/>
  },
  {
    path: "/principal",
    element: <Principal/>,
  },
  {
    path: "/admin",
    element: <Admin/>,
  },
  {
    path: "/sucursales",  
    element:<Main/>

  },
  {
    path: "/register_user",
    element: <RegisterUser/>,
  },
  {
    path: "/reportes_supervisor",
    element: <ViewReportesSupervisor/>,
  },
    {
    path: "/delete_user",
    element:<DeleteUser/>
  },
  {
    path: "/delete_sos_user",
    element:<DeleteSOSUser/>
  },
  {
    path: "/rescue_account",
    element:<Rescue_account/>
  },
  {
    path: "/homeTemplate",
    element:<HomeTemplate/>
  }
    
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <>
    <RouterProvider router={router} />
  </>
);