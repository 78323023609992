import styles from "./css/HomeTemplate/main.css"

export function HomeTemplate(){

    return <>

        <section className="window-container" style={styles}>

            <header>
                <div className="logo">

                </div>
                <div className="sections">
                    <a href="home" className="focus">Home</a>
                    <a href="#plans">Planes</a>
                    <a href="#Contact">Contactanos</a>
                </div>
                <div className="loginbtn">
                    <a href="/login">
                        Iniciar Sesion
                    </a>
                </div>
            </header>
            <div className="backgroun-filter"></div>
        </section>
    
    </>
} 