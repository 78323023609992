import { ip } from "../../GlobalData"
export default function DeleteUserApi(credentials){

    var resp = fetch(`${ip}/protected/delete_user`,{
        method:'POST',
        headers:{
            'Content-Type':"application/json"
            
        },
        body:JSON.stringify(credentials)
    })

    return resp

}