import { api_key_google_maps} from "../../GlobalData";
import { useRef, useEffect } from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import styles from "../css/MapSectionStyles/RouterViewerMapComponent.css"
<link rel="stylesheet" href="/css/map.css" />


const render = (status) => {
  switch (status) {
    case Status.LOADING:
      return <h2>loading</h2>;
    case Status.SUCCESS:
      return <MyMapComponent />;
    default:
      return
  }
};
function MyMapComponent({
  Recorrido,
  setReporte,
  CurrentGeocerca
}) {
  var CircleCoords = null
  if(CurrentGeocerca.length > 0){
    CircleCoords =  CurrentGeocerca.map(r=>{
      return [JSON.parse(r[1].replace(/'/g,"\"")),r[2]]
    })
  }else{
    CircleCoords = []
  }
  var circle = CircleCoords?CircleCoords[0]:{}
  let map = ""
  const coords = JSON.parse(Recorrido[0].replace(/'/g,"\"")).map(r=>{

      return {
        lat:r.latitude,
        lng:r.longitude
      }

  })
const center = CircleCoords.length > 0 ?CircleCoords[0][0]:coords[0]
const ref = useRef();
      useEffect(() => {
        map = new window.google.maps.Map(ref.current, {
          center,
          zoom:18,
          disableDefaultUI: true,
          mapTypeId: 'hybrid'


        })

  if(CircleCoords.length > 0){
      const flightPathgeocerca = new window.google.maps.Polyline({
        path: CircleCoords.map(r=>r[0]),
        geodesic: true,
        strokeColor: "#000000",
        strokeOpacity: 1.0,
        strokeWeight: 2,
      });
      flightPathgeocerca.setMap(map);

      
      CircleCoords.forEach(r=>{
        circle.push(new window.google.maps.Circle({
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#FF0000",
          fillOpacity: 0.35,
          map,
          center:r[0],
          radius: parseInt(r[1]),
        }))

      })

  }  
const flightPath = new window.google.maps.Polyline({
  path: coords,
  geodesic: true,
  strokeColor: "#000000",
  strokeOpacity: 1.0,
  strokeWeight: 2,
});

coords.forEach((coord,i)=>{

  const marker = new window.google.maps.Marker({
    position: coord  ,
    title: `${i+1}`,


  });

  marker.addListener("click", () => {
      setReporte(`${i}`)
    });
  
    marker.setMap(map)
  })

  flightPath.setMap(map);

})


return<div ref={ref} id="map" styles={styles} />
}

export function SetMap({Recorrido,setReporte,CurrentGeocerca}) {
  const zoom = 18;
  var recorrido = []
  if(Recorrido !== undefined && Recorrido.length > 0){
    recorrido = Recorrido[4]
  }else{
    recorrido = "[]"
  }
  return (
    <Wrapper apiKey={api_key_google_maps} render={render} >
       <MyMapComponent zoom={zoom} Recorrido={[recorrido]}  setReporte={setReporte} CurrentGeocerca={CurrentGeocerca}>
      </MyMapComponent> 
    </Wrapper>
  )

}

