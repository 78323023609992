import { ip } from "../GlobalData"
import { useEffect, useState } from "react"
import LoadingSpinner from './Components/LoadingSpinner'
import {sendRescueemailApi} from "./api/sendRescueemailApi"
export  function Login() {
    const [loading,setloading] = useState(true)
    const [rescuePass,setRescuePass] = useState(false)
    function timming() { 
        return new Promise(resolve => setTimeout(() => resolve(), 1500));
      } 


    useEffect(()=>{
       timming().then(()=>{
        setloading(false)
       })

    })

    var loginbtn = ()=>{
        
        var petition = {
            'user':document.forms.login.elements.user.value,
            'passwr':document.forms.login.elements.password.value
        }

        fetch(`${ip}/web/login`,{
            method:'POST',
            headers:{
                'Content-Type':"application/json"
                
            },
            body:JSON.stringify(petition)

        }).then(
            r=>r.json()


        ).then(r=> {
            if(r[0] !== "error"){
            window.localStorage.setItem("credentials",r)
            
            window.localStorage.setItem("userName",document.forms.login.elements.user.value)
            window.location = "/principal/"
            }else{
                alert("contraseña incorrecta")
            }
        }).catch(r=> alert("contraseña incorrecta"))
    }
    const [user, setUser] = useState("");
    const [passwr, setPasswr] = useState("");


    function onChangeTagInput(e,set) {
        set(e.target.value.replace(/[^a-zA-Z \d]/ig, ""));
    }



    return<>
    {
        loading ?<>
        
        <LoadingSpinner/>
        
        </>:""
    }
    <main>

    <link rel="stylesheet" href="./css/login.css" />
    <div className="logo"><img src="./img/logo_med.png" alt="logo" /></div>
        <div className="title">Bienvenidos</div>
        <form name="login" method="POST">
            <input style={{margin:"30px"}} type="text" name="user" className="form" id="" placeholder="Usuario" value={user} onChange={(e) => onChangeTagInput(e,setUser)}  autoComplete="off"/>
            <input type="password" className="form" placeholder="Contraseña" name="password" value={passwr} onChange={(e) => onChangeTagInput(e,setPasswr)} id="" autoComplete="off"/>
            <input type="button" className="submit" value="Entrar" id=" loginbtn" onClick={loginbtn} />
            <div className="remember-passwr" onClick={()=>setRescuePass(true)}>
                Recuperar contraseña
            </div>
        </form>

    </main>
    
    {
        rescuePass?<>
        
        <div className="input_layout recuperation_email">
        <div className="panel">
            <div className="g1"></div>
            <div className="g2">

                <div className="quit" onClick={()=>setRescuePass(false)}></div>


            </div>
        </div>
        <div className="text">
            Enviar Correo de recuperacion
        </div>
        <input type="text" name="Gmail" className="email text"/>
        <input type="button" className="btn" value="Enviar" onClick={sendRescueEmail}/>

    </div>
        </>:""
    }
    </>

}

const sendRescueEmail = ()=>{

    const email = document.querySelector(".email").value
    if(email.includes("@") && email.includes(".com") && email.length > 8){
        

        sendRescueemailApi({
            "email":email
        }).then(r=>r.json()).then(r=>{
            console.log(r);
                if(r[0] === "1"){
                alert("Correo de recuperacion enviado")
                    return 0
                }               
                
                if(r[0] === "2"){
                    alert("Correo no encontrado")
                    return 0

                }
                if(r[0] === "0"){
                    alert("Error desconocido")
                    return 0

                }

        })


    }




}