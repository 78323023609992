import { useEffect,useRef } from "react"
import { api_key_google_maps } from "../../GlobalData"
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import {createQRSucursalApi} from "../api/createQRSucursalApi"
import styles from "../css/MapSectionStyles/map_component.css"
export function MapComponentCreateSucursal({map,circleCoords,QRcircle,setaddQRSucursal}) {
  var polilyne = ""    
  var radius = 20
    
    function MyMapComponent({zoom
    }) {
      const center = { lat: 10.179105014029147, lng: -67.98726690724945};
      const ref = useRef();

      useEffect(() => {
        map = (new window.google.maps.Map(ref.current, {
          center,
          zoom,
          mapTypeId: 'hybrid'

            
        }))
        // setMap(map)
        const locationButton = document.createElement("button");
    
        locationButton.textContent = "Ubicacion Actual";
        locationButton.classList.add("control-button");
        locationButton.style.margin = "10px 0"
        locationButton.style.background = "white"
        locationButton.style.padding = "9px 5px"



        map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(locationButton);
        locationButton.addEventListener("click", () => {
            if (navigator.geolocation != undefined) {
              navigator.geolocation.getCurrentPosition(
                (position) => {
                  const pos = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                  };
                  map.setCenter(pos);
                  map.setZoom(17)
                },
                (e) => {
                  alert("Servicio de ubicacion desactivado, por favor activar")

                }
              );
            } else {
              alert("Servicio de ubicacion desactivado, por favor activar")
            }
          })
        
        map.addListener("click",(r)=>{
            
            circleCoords = {lat: r.latLng.lat(), lng: r.latLng.lng()}

    
            QRcircle.push(new window.google.maps.Circle({
                  strokeColor: "#FF0000",
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillColor: "#FF0000",
                  fillOpacity: 0.35,
                  map,
                  center:circleCoords,
                  radius: parseInt(radius),
                }))
            if(QRcircle.length >= 1){
               setPolilyne()

            }
        

            document.querySelector("#input1").addEventListener("input",ev=>{
                radius= ev.target.value
                QRcircle[QRcircle.length-1].setRadius(parseInt(radius))
            })
            console.log(QRcircle);
        })})
    
    
      return <>
      <div ref={ref} id="map" />
       </>
    }
    
    const render = (status) => {
        switch (status) {
          case Status.LOADING:
            return <h2>loading</h2>;
          case Status.SUCCESS:
            return <MyMapComponent />;
            default :
                return
        }
      };

    const zoom = 3;

    
    function setPolilyne() {
      var polilyneCoords = QRcircle.map(r=>{

          return {
              lat:r.center.lat(),
              lng:r.center.lng()
          }
  })
      if(QRcircle.length > 1){

          if(polilyne !== "" ) polilyne.setMap(null)


          polilyne = new window.google.maps.Polyline({
              path: polilyneCoords,
              geodesic: true,
              strokeColor: "#000000",
              strokeOpacity: 1.0,
              strokeWeight: 2,
          }); 

          polilyne.setMap(map)

      }else{
              if(polilyne !== "" ) polilyne.setMap(null)
      }
      }

    function delLastMarker(){
      QRcircle[QRcircle.length-1].setMap(null)
      QRcircle.pop()
      setPolilyne()
    }
  
    return (
      
      <div className="map_component" style={styles}>
      <header>
          <input type="text" className="form-table-name" name="table-name" placeholder="Nombre de la Sucursal"/>
          <div className="icon_close"><img alt="Boton Cerrar" src="./img/icon_close.png"  onClick={()=>setaddQRSucursal("0")}/></div>
      </header>
      <div className="map-create">
      <Wrapper apiKey={api_key_google_maps} render={render}>
         <MyMapComponent zoom={zoom} >
        </MyMapComponent> 
      </Wrapper>

      </div>
      <div className="buttons">
          <input type="range" min="5" max="400" className='range'id="input1" />
          <div className="button">
              <img src="./img/icon-aceptar.png" alt="Boton Aceptar" onClick={()=>{

                      if(QRcircle.length !== 0 && !/[^a-zA-Z \d]/ig.test(document.querySelector(".form-table-name").value) &&
                      document.querySelector(".form-table-name").value !==""
                      )return createQRSucursalApi(
                          QRcircle.map(r=>{

                              return {"coords":[r.center.lat(),r.center.lng()],"radius":r.radius}

                          })
                          
                            )
                      
                      alert("Debes completar el Formulario sin colocar acentos ni caracteres especiales")

              }}/>
          </div>
          <div className="button" onClick={delLastMarker}>
              <img src="./img/icon_del_marker.png" alt="" />
              </div>
          </div>
  </div>
     
    )}
    