import { useEffect } from "react"
import StyleServicesCompany from "../css/StyleServicesCompany.module.css"
export function ServicesCompany(){

    const text = {
        "title":"Servicios Destacados:",
        "text":[{
            "service":"Desarrollo de Aplicaciones",
            "imgs":[
                "/img/image_android3.png",
                "/img/image_android4.png"
            ],
            "description":" Creamos aplicaciones móviles y de escritorio que destacan en funcionalidad y diseño, adaptadas a tus necesidades específicas.  "
        },
        {
            "service":"Software Personalizado",
            "imgs":[
                "/img/image5.jpg"
            ],
            "description":" Desarrollamos software a medida para optimizar procesos internos, mejorar la eficiencia y potenciar el crecimiento de tu empresa.   "
        },
        {
            "service":"Diseño de Páginas Web",
            "imgs":[
                "/img/image_android1.png",
                "/img/image_android2.png"
            ],
            "description":"Desde páginas web corporativas hasta tiendas en línea, nuestro equipo de diseñadores y desarrolladores creará una presencia en línea impactante y funcional.                                                                             "
        },
        {
            "service":"Asesoramiento integral en el área de tecnología aplicada a la seguridad y gestión de riesgos",
            "imgs":[
                "/img/image_android1.png",
                "/img/image_android2.png"
            ],
            "description":" Evaluamos, asesoramos y desarrollamos, de acuerdo a sus necesidades, software y aplicaciones web y móviles, enfocadas a las soluciones de seguridad que puedan facilitar y garantizar la protección de tus activos más importantes. "
        },
        {
            "service":"Asesoramiento, diseño y automatización de plantillas de Excel",
            "imgs":[
                "/img/image_android1.png",
                "/img/image_android2.png"
            ],
            "description":" Te brindaremos asesoramiento personalizado para entender tus necesidades específicas, diseñando plantillas de Excel intuitivas y funcionales, adaptadas a tu flujo de trabajo. ",
        }
        ]
    }

    useEffect(()=>{

        document.onscroll = (r)=>{
            var barra = window.scrollY
            var posicion = barra * 0.20;
            if (posicion < 250 && window.innerWidth < 1600) {
                document.querySelector("#image_parallax").style.backgroundPosition = -posicion  + 'px'
            }else{

            }
        }


        setTimeout(() => {
            document.querySelectorAll("#container_services").forEach(r=>{
               var element = r.style
               element.opacity = "1"
               element.transform = "translate(0)"

           })
       }, 50);


    },[])

    return <>
        <div className={StyleServicesCompany.ServicesCompany} >

           {
            text.text.map(r=>{


                    return <div className={StyleServicesCompany.card} id="container_services">
                    

                        <div className={StyleServicesCompany.text} >
                            {r.service}
                        </div>
                    <div className={StyleServicesCompany.description}>
                        {r.description}
                    </div>
                </div>

            })
           }
        <div className={StyleServicesCompany.parallax_image} id="image_parallax"> </div>
        </div>

    </>
}