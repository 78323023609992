import { ip } from "../../GlobalData"


export function getReportsPerCursor(credentials,sucursal,cursor){

    return fetch(`${ip}/web/getReportsperCursor`,{
        method:'POST',
        headers:{
            'Content-Type':"application/json"
            
        },
        body:JSON.stringify({
            "sucursal":sucursal,
            "credentials":credentials,
            ...cursor
        })

    })


}