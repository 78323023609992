import { getReport } from "../../api/getReportApi"
import stylesViewReport from "./css/card_recorrido.module.css"
export const ComponentViewReport = ({idRecorrido,reporte,setReporte,title})=>{

    return<>
    <div className={`${stylesViewReport.reporte} ${stylesViewReport.hide}`}
        id="report_component">
        <div className={stylesViewReport.close}
        onClick={()=>{

            document.querySelector("#report_component").classList.toggle(stylesViewReport.hide)
            
        }}>
            <img src="./img/icon_close.png" />
        </div>
        <img className={stylesViewReport.reporte_img}
        id="reporte_img"/>
        <div className={stylesViewReport.description}
        id="description">
            <div className={stylesViewReport.texto}
            id="texto"></div>
            <div className={stylesViewReport.fecha}
            id="fecha"></div>
        </div>
        <div className={stylesViewReport.hide_report_buttom}
        id="hideReportRbuttom"
        onClick={(r)=>hideDescription(r)}>
            <img 
            src="./img/icon_arrow_left.png" 
            />
        </div>
    </div>
    </>
}

function hideDescription(r) {
    var el = document.querySelector("#description")
    if (el.style.bottom === "0px") {
        el.style.bottom = "-100%"
        document.querySelector("#hideReportRbuttom").style.transform = "rotate(-90deg)"

    }
    else{
        el.style.bottom = "0px"
        document.querySelector("#hideReportRbuttom").style.transform = "rotate(90deg)"
    }

}