
import { getReport } from "../api/getReportApi"
export const Reporte = ({idRecorrido,reporte,setReporte,tableName})=>{
    
    var petition = {
        "tableName":tableName,
        "index":reporte,
        "idRecorrido":idRecorrido
    }
    console.log(petition);
        
    
    getReport(window.localStorage.getItem("credentials"),petition).then(r=>r.json()).then(r=>{
                if(r.image !== "0") {
                var image = document.querySelector(".reporte .reporte_img ")
                image.src = `data:image/png;base64,${r.image}`
                }
                document.querySelector(".reporte .description .texto").innerHTML = `${r.description}`
                document.querySelector(".reporte .description .fecha").innerHTML = `${r.date}`


            }
        )

    function hideDescription(r) {
        var el = document.querySelector(".reporte .description")
        if (el.style.bottom === "0px") {
            el.style.bottom = "-100%"
            document.querySelector(".hide-report-buttom").style.transform = "rotate(-90deg)"

        }
        else{
            el.style.bottom = "0px"
            document.querySelector(".hide-report-buttom").style.transform = "rotate(90deg)"
        }

    }


    return<>
    <link rel="stylesheet" href="/css/card_recorrido.css" />
    <div className="reporte">
        <div className="close" onClick={()=>setReporte(0)}>
            <img src="./img/icon_close.png" />
        </div>
        <img className="reporte_img"/>
        <div className="description">
            <div className="texto"></div>
            <div className="fecha"></div>
        </div>
        <div className="hide-report-buttom"
        onClick={(r)=>hideDescription(r)}>
            <img 
            src="./img/icon_arrow_left.png" 
            
            />
        </div>
    </div>

    </>

}