import petitionDownloadWithExcel from "../api/petitionDownloadWithExcel"

export default function downloadWithExcel(sucursal,cursor){
    petitionDownloadWithExcel(
        window.localStorage.getItem("credentials"),sucursal,cursor
    ).then( res => res.blob() )
    .then( b => {
        var a = document.createElement("a")
        a.href = URL.createObjectURL(b)
        a.setAttribute("download", `${sucursal.replace(' ','_')}.xlsx`)
        a.click()
    })
}