import { ip } from "../../GlobalData"

export default function CardUser({user,setDelUser}){

    return<>
            
    <li key={user[0]}>
    <div className="name">{user[1]}</div>
    <div className="correo">{user[9]}</div>

    <div className="reportes_num">{user[15]!=undefined?user[15]:0}/{user[12]}</div>
    <div className="is_active">{user[6] == 1?"si":"no"}</div>
    <div className="options">

        <img src="./img/icon_config.png" onClick={e=>editUser(user[0])}/>
        <img src="./img/icon_del.png" id={user[1]} onClick={  (e)=>setDelUser(user[1]) }/>

    </div>
</li>
    
    </>

}
const editUser = (id)=>{

    const respPetition = petitionGetUser(id).then(r=>r.json()).then(r=>{


    document.querySelector(".formUser").style.display = "flex"
    document.querySelector(".formUser .title").innerHTML = "Editar Usuario"
    document.querySelector(".formUser input[name=\"user\"]").id = id
    document.querySelector(".formUser input[name=\"user\"]").value = r[1]
    document.querySelector(".formUser input[name=\"passw\"]").value = r[2]
    document.querySelector(".formUser input[name=\"userLimit\"]").value = r[7]
    document.querySelector(".formUser input[name=\"qrLimit\"]").value = r[8]
    document.querySelector(".formUser input[name=\"reportLimit\"]").value = r[12]
    document.querySelector(".formUser input[name=\"tableLimit\"]").value = r[13]
    document.querySelector(".formUser input[name=\"qrAreaLimit\"]").value = r[14]
    })
}




const petitionGetUser = id=>{

    

    var petition = [window.localStorage.getItem("credentials"),id]

    var resp = fetch(`${ip}/protected/getUser`,{
        method:'POST',
        headers:{
            'Content-Type':"application/json"
            
        },
        body:JSON.stringify(petition)
    })

    return resp
}
