import React from "react";

export default function LoadingSpinner() {
  return <>
      <link rel="stylesheet" href="./css/spinner.css" />
  
    <div className="spinner-container">
      <div className="loading-spinner">
      </div>
    </div>
  </>
    
}