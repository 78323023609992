export  function deleteSOSUserApi(credentials){
    var resp = fetch(`https://www.marckygps.com/dev/protected/delete_user_sos`,{
        method:'POST',
        headers:{
            'Content-Type':"application/json"
            
        },
        body:JSON.stringify(credentials)
    })

    return resp

}