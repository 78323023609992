export default function SucursalLists({List,changeSucursal,title,setCardDelTable,listQRSucursal,changeSucursalQR,setaddQRSucursal}){

    return<>
    <link rel="stylesheet" href="./css/SucursalList.css" />
    
    <div className="SucursalList">
            <ul>
                <div className="subtitle">Marcador por Puntos</div>
                    {
                        listQRSucursal.map((r,i)=>{
                            
                                return <li key={i} onClick={()=>{changeSucursalQR(r[1])}}>{r[1]} <div className="icon-del" onClick={()=>setCardDelTable(r[1])}></div></li>
                            
                        })
                    }
                <li className="icon-add" onClick={()=>setaddQRSucursal(1)}></li>
            </ul>
            
    </div></>
}