import StyleContactInfo from "../css/StyleContactInfo.css"
export function Contactanos({Information}){

    return <div className="contactInfo" id="Contact">

            <ul className="ContactListInfo">

                <li className="InnerText">
                    <div className="title">
                        Contactanos!!
                    </div>
                    <div className="text">
                    {
                        Information.info[0]
                    }   
                    </div>

                </li>
                <li className="contactdata">
                    <div className="data1">{Information.info[1][0]}</div>
                    <div className="data2">{Information.info[1][1]}</div>
                    <div className="data3">{Information.info[1][2]}</div>
                </li>


                <li className="FooterText">
                    {Information.info[2]}
                </li>
            </ul>

    </div>
}