import { ip } from "../../GlobalData"
export function delTablePetition(id){

    var petition = [window.localStorage.getItem("credentials"),id]

    var resp = fetch(`${ip}/protected/delTable`,{
        method:'POST',
        headers:{
            'Content-Type':"application/json"
            
        },
        body:JSON.stringify(petition)
    })

    return resp

}