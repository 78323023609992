import { useEffect, useState } from "react"
import { ip, api_key_google_maps } from "../GlobalData"
import {CardRecorrido} from './Components/CardRecorrido'
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import LoadingSpinner from './Components/LoadingSpinner'

export function ViewReportesSupervisor(){
    const [loading,setloading] = useState(true)
    const [sucursalName,setsucursalName] = useState(false)
    const [sucursalQrName,setsucursalQrName] = useState(false)
    const [ReportsList,setReportList] = useState([])
    const [RecorridoQr,setRecorridoQr] = useState([])
    const [ReportsQRList,setReportQRList] = useState([])
    const [AsisatenciaArea,SetAsistenciaArea] = useState(false)
    const [reporte,setReporte] = useState("")
    const [reporteQR,setReporteQR] = useState("")


    const [Recorrido,setRecorrido] = useState([])
    var map = ""

    const petition = [window.localStorage.getItem("credentials")]
    useEffect(()=>{
        fetch(`${ip}/supervisor/getTable`,{
            method:'POST',
            headers:{
                'Content-Type':"application/json"
                
            },
            body:JSON.stringify(petition)
        }).then(r=>r.json()).then(r=>{
            
                setReportList(r.reportes)
                
                if(r.tableName.length != 0 ) setsucursalName(r.tableName[0])
                if(r.QrtableName.length != 0 ) setsucursalQrName(r.QrtableName[0])
                if(r.reportesQR.length != 0 ) setRecorridoQr(r.reportesQR)
                if(r.asistenciasQR.length != 0) SetAsistenciaArea(r.asistenciasQR[0][0])
        })  
    

    },[])
    const closeMap= ()=>{

        setRecorrido([])
        setReportQRList([])
    }
    const showMaps = (id)=>{

        getRecorridoPetition(id,sucursalName).then(r=>r.json()).then(r=>{
        setRecorrido(r)
        })
    } 
    const showQrMaps = (id)=>{

        getRecorridoPetition(id,sucursalQrName[0]).then(r=>r.json()).then(r=>{
            setReportQRList(r)
        })
    } 
    const downloadWithExcel = (sucursal)=>{
        const resp = petitionDownloadWithExcel(
            sucursal
        ).then( res => res.blob() )
        .then( b => {
            var a = document.createElement("a")
            a.href = URL.createObjectURL(b)
            a.setAttribute("download", "excel.xlsx")
            a.click()
        })
    }
    function timming() { 
        return new Promise(resolve => setTimeout(() => resolve(), 1500));
      } 


    useEffect(()=>{
       timming().then(()=>{
        setloading(false)
       })

    })


    function deploy(e) {
        if(e.target.parentNode.parentNode.style.height === "auto"){
            e.target.parentNode.parentNode.style.height = "100px"
            e.target.parentNode.parentNode.style.overflowy = "hidden"
            e.target.style.transform = "rotate(-90deg)"


        }else{
        e.target.parentNode.parentNode.style.overflowy = "scroll"
        e.target.parentNode.parentNode.style.height = "auto"
        e.target.style.transform = "rotate(0deg)"
        }
        

    }
    return<>
     {
        loading ?<>
        
        <LoadingSpinner/>
        
        </>:""
    }
    <link rel="stylesheet" href="./css/recorridos.css" />
    <link rel="stylesheet" href="./css/Header.css" />
    <link rel="stylesheet" href="./css/supervisor.css" />
    
    {sucursalName?<>
        <div className="section">
    <header>
        <div className="clientes">{sucursalName}</div>
        <div className="deploy"  onClick={(e)=>{deploy(e)}}>&#9660;</div>
        <div className="download-with-excel" onClick={()=>downloadWithExcel(sucursalName)}>
            <img src="./img/icon_excel.png"/>
        </div>
    </header>
        <div className="list_clientes_header">
        <div className="tableName">Nombre de usuario</div>
        <div className="options">Fecha</div>
        
        <div className="id">ID</div>
        </div>
        <ul className="list_clientes">
            {ReportsList.map((r,i)=><>
                <li key={i+1} onClick={()=>showMaps(r[0])}>
                    <div className="name" >{r[2]}</div>
                    <div className="fecha">
                        {JSON.parse(r[7])[0]}
                    </div>
                    <div className="id">{i+1}</div>

                </li> 
            </>)}
        </ul>
    </div>
    </>:""}

    {sucursalQrName?<>
    <div className="section">    
    <header>
        <div className="clientes">{sucursalQrName}</div>
        <div className="deploy" onClick={(e)=>{deploy(e)}}>&#9660;</div>
        <div className="download-with-excel" onClick={()=>downloadWithExcel(sucursalQrName[0])}>
            <img src="./img/icon_excel.png"/>
        </div>
    </header>
    <div className="list_clientes_header">
    <div className="tableName">Nombre de usuario</div>
    <div className="options">Fecha</div>
    <div className="id">ID</div>
    </div>
    <ul className="list_clientes">
        {RecorridoQr.map((r,i)=><>
            <li key={r[2]} onClick={()=>showQrMaps(r[0])}>
                <div className="name" >{r[2]}</div>
                <div className="fecha">
                    {r[1]}
                </div>
                <div className="id">{i+1}</div>

            </li> 
        </>)}
    </ul>

    </div></>:""}
    
    {Recorrido.length != 0?<CardRecorrido data={Recorrido} closeMap={closeMap}  sucursalName={sucursalName} />:""}

   {AsisatenciaArea?<>
    <div className="section">
    <header>
            <div className="clientes">{AsisatenciaArea}</div>
            <div className="download-with-excel" onClick={()=>downloadAsistenciaWithExcel(AsisatenciaArea)}>
                <img src="./img/icon_excel.png"/>
            </div>
        </header>
   </div>
   </>:""}
    </>
}

function downloadAsistenciaWithExcel(AsistenciaArea) {
    const petition = [window.localStorage.getItem("credentials"),AsistenciaArea]
    var resp = fetch(`${ip}/web/downloadQRAreaWithExcel`,{
        method:'POST',
        headers:{
            'Content-Type':"application/json"
            
        },
        body:JSON.stringify(petition)

    }).then( res => res.blob() )
    .then( b => {
        var a = document.createElement("a")
        a.href = URL.createObjectURL(b)
        a.setAttribute("download", "excel.xlsx")
        a.click()
    })
}
const petitionDownloadWithExcel = (tableName)=>{
    
    const petition = {
        "credentials":window.localStorage.getItem("credentials")
        ,"sucursal":tableName,
        "from_date":null,
        "to_date":null
        
    }
    var resp = fetch(`${ip}/web/downloadTableWithExcel`,{
        method:'POST',
        headers:{
            'Content-Type':"application/json"
            
        },
        body:JSON.stringify(petition)

    })


    return resp

}
const getRecorridoPetition = (id,tableName)=>{

    const petition = [JSON.stringify([window.localStorage.getItem("credentials")]),tableName,id]
    var resp = fetch(`${ip}/web/getRecorrido`,{
        method:'POST',
        headers:{
            'Content-Type':"application/json"
            
        },
        body:JSON.stringify(petition)

    })


    return resp

}