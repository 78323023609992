import { useEffect } from "react"
import SettingsComponentStyles from "./css/settings_component.css"

export function SettingsComponent({
    configs,
    setConfigs,
    sendPetition
}){ 

    // var _config_data_model = {
        // "principal_title":"",
        // "sections":[{
        //     "section_title":"",
        //     "confir":[{
        //         "config_title":"",
        //         "config_description":"",
        //         "config_action":""
        //     }]
        // }]

    // }

    useEffect(()=>{

        setTimeout(() => {

            document.querySelector(".settings_component").style.top = "0"

            
        }, 100);
    })

    return<section className={"settings_component"} style={SettingsComponentStyles}>

        <div className="settings-header">
            {configs.principal_title}
        </div>
        <div className="confs_container_main">

            {configs.sections.map((conf)=>{
                
                return <article>
                <div className="title">
                    {conf.section_title}
                </div>
            <div className="configs_container">
                {conf.config.map(r=>{
                    return <div className="config">

                    <div className="config_name">
                        <input type="checkbox" disabled className="check_configs" id={r.config_action} />
                        {r.config_title}
                    </div>
                    <div className="config_description">
                        {r.config_description}
                    </div>
                    </div>
                    })}
            </div>
            </article>
            })}

        </div>
        <div className="actions">
            <div className="cancel" onClick={()=>{
                document.querySelector(".settings_component").style.top = "-600px"
                setTimeout(() => {
                setConfigs(null)
                }, 800);
            }}>Cancelar</div>
            <div className="accept" onClick={()=>sendPetition(document.querySelectorAll(".check_configs"))}>Aceptar</div>
        </div>
    </section>}