import { useEffect, useState } from "react"
import downloadWithExcel from "../../Controllers/DownloadWithExcel";
import style from "./css/RoutesListComponent.css"
import {DatePickerComponent} from "../DatePickerComponent/DatePickerComponent"
import { getReportsPerCursor } from "../../api/getReportsPerCursor";
import { SettingsComponent } from "../SettingsComponent/SettingsComponent";
import { apiPostBackup } from "./api/apiPostBackup";
export default function RecorridoList({Routes,setCurrentRoute,sucursal}) {
    const [datetimeReport,setdatetimeReport] = useState({})
    const [routeList, setRouteList] = useState(Routes)
    const [varSettingsComponent,setVarSettingsComponent] = useState(false)
    var rindex = 1
    function ChangeRoute(r) {
        setCurrentRoute(r)
        document.querySelector('.RecorridoList').classList.toggle('hide')
        document.querySelector('.recorridoslistBack .icon-back').classList.toggle('hide')
    }

    useEffect(()=>{
            if(datetimeReport){
                getReportsPerCursor(
                    window.localStorage.getItem("credentials"),
                    sucursal,
                    {
                        "from-date":datetimeReport.startDate?datetimeReport.startDate:null,
                        "to-date":datetimeReport.endDate?datetimeReport.endDate:null
                    }
                ).then(r=>r.json()).then((r)=>{
                    setRouteList({
                        "list":r.routes
                    })
                })

            }
    },[datetimeReport,sucursal])

    return<>
        {
            varSettingsComponent?<SettingsComponent setConfigs={setVarSettingsComponent} configs={varSettingsComponent} sendPetition={
                (r)=>{
                var configList = []
                r.forEach(e=>{
                    if(e.checked){configList.push(e.id)}
                })

                    const request = {
                "credentials":window.localStorage.getItem("credentials"),
                "sucursal":sucursal,
                "from-date":datetimeReport.startDate?datetimeReport.startDate:null,
                "to-date":datetimeReport.endDate?datetimeReport.endDate:null,
                "confs":configList
                }
                
                apiPostBackup(request).then(r=>r.blob()).then( b => {
                    var a = document.createElement("a")
                    a.href = URL.createObjectURL(b)
                    a.download = 'filename.zip';
                    // a.setAttribute("download",  `${sucursal.replace(' ','_')}.zip`)
                    a.click()
                    
                }).catch(r=>{
                    alert(r)
                })
                }
                
            }

            />:""
        }
    <div className="RecorridoList" style={style}>
    <header>
        <div className="download-with-excel" onClick={()=>{downloadWithExcel(routeList.list[0][3],{
                        "from_date":datetimeReport.startDate?datetimeReport.startDate:null,
                        "to_date":datetimeReport.endDate?datetimeReport.endDate:null
                    })}}>
            <img src="./img/icon_excel.png" alt="icono Excel"/>
        </div>
        <div className="icon-reload" onClick={()=>{setdatetimeReport({})}}>
            <img src="./img/icon-reload.png" alt="Recargar" style={{padding:"5px"}} />
        </div>
        <div className="icon-save-data" onClick={()=>{
            
            setVarSettingsComponent(
                    {
                        "principal_title":"Respaldar datos",
                        "sections":[{
                            "section_title":"Servidor",
                            "config":[{
                                "config_title":"Eliminar registros descargados",
                                "config_description":"Eliminar los Recorridos seleccionados de la base de datos del servidor una vez completado el respaldo y la descarga de sus datos. ",
                                "config_action":"DELETE_DATA"
                            }]
                        },
                                             
                    ]
        
        
            }
                
            )
        }}>
            <img src="./img/icon-save-data.png" alt="Respaldar datos" style={{padding:"5px"}} />
        </div>
    </header>

    <div className="list_clientes_header">
    <div className="tableName">Nombre de usuario</div>
    <DatePickerComponent setdatetimeReport={setdatetimeReport}/>
    <div className="id">ID</div>
    </div>
    <ul className="list_clientes">
        {routeList.list.map((r,i)=> {return r[5] !== "[]"
        ?<>{
            
            <li  key={i} onClick={()=>{ChangeRoute(r)}}>
                <div className="name" >{r[2]}</div>
                <div className="fecha">
                    {JSON.parse(r[8])[0]}
                </div>
                <div className="id">{rindex++}</div>

            </li> 
            
        }
        </>:""})}
    </ul>
    <div className="recorridoslistBack" onClick={()=>{document.querySelector('.RecorridoList').classList.toggle('hide');document.querySelector('.recorridoslistBack .icon-back').classList.toggle('hide')}}>
        <div className="icon-back"></div>
        <div className="deploy-icon" ></div>
    </div>
    </div>
    </>
    
}