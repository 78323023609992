import {  useState } from "react"
import { getSucursalData,getSucursalQRData } from "../api/getSucursalData"
import SucursalLists from "./SucursalList"
import {DeleteSucursal,} from './DeleteSucursal'
import styles from "../css/PrincipalHeaderComponent/Header.css"

export default function Header({SucursalList,title,setCurrentSucursal,SucursalQRList,setaddQRSucursal}){
    const [CardDelTable,setCardDelTable] = useState("")

        const changeSucursal = (sucursal)=>{        
            getSucursalData(
                window.localStorage.getItem("credentials")
                ,sucursal).then(r=>r.json()).then(r=>{
                if(r.SucursalRoutes.length > 0){
                    setCurrentSucursal(
                        {"routes":r.SucursalRoutes,
                        "sucursalName":sucursal,
                        "SupervisorList":r.SupervisorList,
                        "GeoCerca": r.CurrentGeocerca,
                        "section":"1"}    
                )
                }else{
                    setCurrentSucursal(
                        {"routes":[],
                        "sucursalName":sucursal,
                        "SupervisorList":r.SupervisorList,
                        "GeoCerca": r.CurrentGeocerca,
                        "section":"1"}
                    )
                }
            })
        
        }
    function changeSucursalQR(sucursal) {
        getSucursalQRData(window.localStorage.getItem("credentials")
        ,sucursal).then(r=>r.json()).then(r=>{
            var filteretGeoCerca = JSON.parse(r.CurrentGeocerca[0][1].replace(/'/g,"\"")).map(r=>{
                return [
                    0,
                    JSON.stringify(
                    {lat:r.coords[0],
                     lng:r.coords[1]},),
                    r.radius
            ]})
            if(r.SucursalRoutes.length > 0){
                setCurrentSucursal(
                    {"routes":r.SucursalRoutes,
                    "sucursalName":sucursal,
                    "SupervisorList":r.SupervisorList,
                    "GeoCerca": filteretGeoCerca,
                    "section":"2"}
                    
            )

            }else{
                setCurrentSucursal(
                    {"routes":[],
                    "sucursalName":sucursal,
                    "SupervisorList":r.SupervisorList,
                    "GeoCerca": filteretGeoCerca,
                    "section":"2"}
                )
            }
        })
        
    }
  
    return<>

    <header style={styles}>
            <div className="spacing"></div>
            <div className="title" >{title}</div>
            <div className="tools">
            <div className="conf" onClick={()=>DeployConfig()}></div>
            <div className="deploy-icon"onClick={deploySucursalList} ></div>
            </div>
    </header>
    <SucursalLists  setaddQRSucursal={setaddQRSucursal} 
                    changeSucursalQR={changeSucursalQR} 
                    List={SucursalList} 
                    changeSucursal={changeSucursal} 
                    title={title}  
                    setCardDelTable={setCardDelTable} 
                    listQRSucursal={SucursalQRList}/>

    {CardDelTable !== ""?<DeleteSucursal     CardDelTable={CardDelTable} 
                                    setCardDelTable={setCardDelTable}/>:""}
    </>
}


function DeployConfig() {
    document.querySelector(".table_config").style.left = "0"
}

const deploySucursalList = ()=>{
    document.querySelector('.tools').classList.toggle('clientes-focused')
    document.querySelector('.tools .deploy-icon').classList.toggle('deploy-icon-focused')
    document.querySelector('.SucursalList').classList.toggle('deploy-SucursalList')
}