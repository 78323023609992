import React, { useState } from 'react';
import FormUser from './Components/FormUser'
import CardUser from './Components/CardUser'
import { ip } from '../GlobalData';
import LoadingSpinner from './Components/LoadingSpinner'
import { useEffect } from "react"

export function Admin() {

    const [users,setUsers] = useState([])
    const [earringUsers,setEarringUsers] = useState([])
    const [cardDelGmail,setcardDelGmail] = useState("")
    const [earringUser,setearringUser] = useState(false)
    const [loading,setloading] = useState(true)
    const [CardDelUser,setCardDelUser] = useState("")
    if(users.length == 0) loginbtn().then(r=>r.json()).then(r=>setUsers(r))
    if(earringUsers.length == 0) getearringUsers().then(r=>r.json()).then(r=>setEarringUsers(r))
    function timming() { 
        return new Promise(resolve => setTimeout(() => resolve(), 1500));
      } 


    useEffect(()=>{
       timming().then(()=>{
        setloading(false)
       })

    })

    return<>
    {
        loading ?<>
        
        <LoadingSpinner/>
        
        </>:""
    }
    <main>

    <link rel="stylesheet" href="./css/admin.css" />
    <link rel="stylesheet" href="./css/Alert.css" />
    <link rel="stylesheet" href="./css/Header.css" />
    <header className='adm-header'>
            <div className="title" >Admin
        </div>
        <div className="tools">
            <div className="add" onClick={()=>setearringUser(true)}></div>
        </div>
        
    </header>
    

    <ul className="list_clientes">
        
    <ul className="list_clientes_header">
    <div className="name">Nombre</div>
    <div className="correo">Correo</div>
    <div className="reportes_num">Num-reportes</div>
    <div className="is_active">Esta Activo</div>
    <div className="options">Opciones</div>
    </ul>
        {users.map((_user)=>{
            
            if(_user[3]=="1" && _user[1])return <CardUser user={_user} setDelUser={setCardDelUser}/>})}
    </ul>

    <FormUser/>
    

    {
        CardDelUser != "" ?<>    
    <div className="Alert">
    <div className="title">Borrar Usuario</div>
    <div className="description">Desea borrar este Usuario?</div>
    <div className="condition">
    <div className="no" onClick={()=>setCardDelUser("")}>no</div>
    <div className="si" onClick={e=>{delUser(CardDelUser)}}>si</div>
    </div>

    </div>
    </>:""
            
    }
    


    <header  className='adm-header'>
    <div className="title">Pendientes</div>
    </header>
    
   <ul className="list_clientes">
   <ul className="list_clientes_header">
    <div className="name">Gmail</div>
    <div className="options">Opciones</div>
    </ul>
   {
        earringUsers[0] != "empty"?earringUsers.map((user,i)=><>
    
            <li key={user[0]}>
                <div className="name">{user[1]}</div>
                <div className="options">
                    <img src="./img/icon_config.png" onClick={e=>editGmail(user[0])}/>
                    <img src="./img/icon_del.png" id={user[0]} onClick={  (e)=>setcardDelGmail(user[0]) }/>
                </div>
            </li>

        </>):""
        
    }
   </ul>



    {
        cardDelGmail != ""?
        <>
        
        <div className="Alert_del_gmail">

        <div className="title">Borrar gmail</div>
        <div className="description">Desea borrar este Gmail?</div>
        <div className="condition">
        <div className="no" onClick={()=>setcardDelGmail("")}>no</div>
        <div className="si" onClick={()=>delGmail(cardDelGmail)}>si</div>
        </div>

     </div>

        </>:""
    }


    {
        earringUser === true?<>
        



        <form className='formGmail' name='formGmail'>
            <img src="./img/icon_close.png" onClick={()=>setearringUser(false)} className='exit' />
            <div className="title">Crear Gmail</div>
            <input type="text" name="gmail" placeholder='Gmail' />

            <div>
            <span>Limite de recorridos</span>
            <input type="number" name="reportLimit"/>
            </div>
            <div>
            <span>Limite de Usuarios</span>
            <input type="number" name="userLimit"/><br/>
            </div>
            <div>
            <span>Limite de Sucursales</span>
            <input type="number" name="tableLimit"/>  
            </div> 
            <div>
            <span>Limite de QR</span>
            <input type="number" name="qrLimit"/>
            </div>
            <div>
            <span>Limite de Areas QR</span>
            <input type="number" name="qrAreaLimit"/>
            </div>
            
        
            <input type="button" className="submit" value="Entrar" onClick={(e)=>addGmail(setearringUser)}/>

        </form>

        </>:""
    }

    </main>
    </>
    
}
const editGmail = (i)=>{
}
const delGmail = (i)=>{

    var petition = [window.localStorage.getItem("credentials"),
                    i]



    fetch(`${ip}/web/delGmail`,{
        method:'POST',
        headers:{
            'Content-Type':"application/json"
            
        },
        body:JSON.stringify(petition)
    }).then(r=>r.json()).then(r=>{

        window.location.reload()

    })



}
const addGmail = (hide)=>{

    var formGmail = document.querySelector(".formGmail")

    var petition = [window.localStorage.getItem("credentials"),
                    formGmail.gmail.value,
                    formGmail.tableLimit.value,
                    formGmail.reportLimit.value,
                    formGmail.userLimit.value,
                    formGmail.qrLimit.value,
                    formGmail.qrAreaLimit.value

                ]



    fetch(`${ip}/web/addGmail`,{
        method:'POST',
        headers:{
            'Content-Type':"application/json"
            
        },
        body:JSON.stringify(petition)
    }).then(r=>r.json()).then(r=>{

        window.location.reload()

    })




    hide(false)


}
const delUser = (e)=>{




    delUserPetition(e).then(r=>r.json()).then(r=>{

        if(r[0] === "success"){


            document.querySelector(".formUser").style.display = "none"
            window.location.reload()



            }else{

            alert("error")
            document.querySelector(".formUser").style.display = "none"
            window.location.reload()
            


            }
        }
    )




}



const delUserPetition = (id)=>{

    var petition = [window.localStorage.getItem("credentials"),id]

    var resp = fetch(`${ip}/web/protected/delUser`,{
        method:'POST',
        headers:{
            'Content-Type':"application/json"
            
        },
        body:JSON.stringify(petition)
    })

    return resp

}
const loginbtn = ()=>{
        
    var petition = [window.localStorage.getItem("credentials")]

    var resp = fetch(`${ip}/protected/getListUsers`,{
        method:'POST',
        headers:{
            'Content-Type':"application/json"
            
        },
        body:JSON.stringify(petition)

    })

    return resp
    
}

const getearringUsers = ()=>{
    var petition = [window.localStorage.getItem("credentials")]

    var resp = fetch(`${ip}/web/getListearringUsers`,{
        method:'POST',
        headers:{
            'Content-Type':"application/json"
            
        },
        body:JSON.stringify(petition)

    })

    return resp
    
}
