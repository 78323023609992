import { ip } from "../../GlobalData"
export default function petitionDownloadWithExcel(credentials,sucursal,cursor ) {
    
    const petition = {
        "credentials":credentials,
        "sucursal":sucursal,
        ...cursor}
    var resp = fetch(`${ip}/web/downloadTableWithExcel`,{
        method:'POST',
        headers:{
            'Content-Type':"application/json"
            
        },
        body:JSON.stringify(petition)

    })


    return resp
}