import { redirect } from "react-router-dom";
import { SendNewPass } from "./api/SendNewPass";

export function Rescue_account(){





    function SendData() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const form = document.forms["login"]
            if(form["password"].value != form["rpassword"].value){
                alert("Las contraseñas escritas no coinciden")
            }

            SendNewPass({
                "email":form["email"].value,
                "passwrd":form["password"].value,
                "token":urlParams.get('token')
            }).then(r=>r.json()).then(r=>{

                console.log(r);
                switch(r[0]){
                    case "1":
                        alert("Contraseña cambiada")
                        window.location.href = "https://www.marckygps.com";
                        return 
                    case "0":   
                        alert("Error de token, por favor volver a reenviar correo de recuperacion")
                        return 
                        case "2":
                        alert("Correo no registrado")
                        return 
                }

            })
        
    }



    return<>
   <main>

    <link rel="stylesheet" href="./css/login.css" />


    <div className="logo"><img src="./img/logo_med.png" alt="logo" /></div>
        <div className="title" style={{"textAlign":"Center"}}>Recuperación de contraseña</div>
        <form name="login" method="POST">
            <input style={{margin:"30px"}} type="text" name="email" className="form" id="" placeholder="Correo"  autocomplete="off"/>
            <input type="password" className="form" placeholder="Contraseña" name="password" id="" autocomplete="off"/>
            <input type="password" className="form" placeholder="Reescribir Contraseña" name="rpassword" id="" autocomplete="off"/>
            <input type="button" className="submit" value="Entrar" id=" loginbtn" onClick={SendData} />
        </form>

    </main>




    </>
}