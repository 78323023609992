
import { Slider } from "./Components/Slider"
import {ServicesCompany} from "./Components/ServicesCompany"
import PrincipalPageStyles from "./css/principal_page.module.css"
import { WhyCoose } from "./Components/WhyCoose"
import {Plans} from "./Components/Plans"
import { CentrodeAyuda } from "./Components/CentrodeAyuda"
import {Contactanos} from "./Components/Contactanos"
import { useEffect } from "react"
export function PrincipalPage(){
  const PlanInformation = {
    "service":"Marckygps",
    "Plans":[{
          "title":"App de recorridos Marckygps",
          "cost":"",
          "Characteristics":[
            "A partir de 18usd por sucursal activa"
          ]},
          {
            "title":"Paginas web",
            "cost":"",
            "Characteristics":[
              "A partir de 110usd"
            ]},
            {
              "title":"Diseño de apps mobiles",
              "cost":"",
              "Characteristics":[
                "A partir de 200usd"
            ]}
  ]
}

    const ContactInfo = {
        "info":[
          "¡Contáctanos hoy y haz realidad tus ideas tecnológicas!  No dejes que la oportunidad pase.  ",
          ["+58 412-5371284","+58 412-7177394","GESNUTECH@GMAIL.COM"],
          "Estamos listos para hacer que tus proyectos despeguen."
        ]

    }

    useEffect(()=>{
      setTimeout(() => {
        
        const element =document.querySelector("#home_header").style
        element.transform = "translateY(0)"
        element.opacity = "1"
      }, 50);
    })

    return<>
    
    <header className={PrincipalPageStyles.home_header} id="home_header">
      <div className={PrincipalPageStyles.logo}>
      </div>
       <div className={PrincipalPageStyles.nav}>
        <a href="home" className={PrincipalPageStyles.focus}>Inicio</a>
        <a href="#plans">Planes</a>
        <a href="#Contact">Contactanos</a>
      </div>
      <div className={PrincipalPageStyles.login}>
        <a href="/login">
          Iniciar Sesion
        </a>
      </div>
    </header>
    <Slider/>
    <ServicesCompany/>
    <WhyCoose/>
    <Plans Information={PlanInformation}/>
    <CentrodeAyuda/>
    <Contactanos Information={ContactInfo}/> 
    
    </>
}