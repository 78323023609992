import {deleteSOSUserApi} from "./api/deleteSOSUserApi.jsx"
export default function DeleteSOSUser(){

    const styleDialog = {
            textAlign:"center",
            margin:"10px 0"

    }
    function deleteSOSUser(){
            const form = document.querySelector(".login")

            if (form.password[0].value != form.password[1].value) return alert("Las contraseñas no coinciden")            
            

            const credentials = {
                "user":form.user.value,
                "password":form.password[0].value

            }
            deleteSOSUserApi(credentials).then(r=>r.json()).then(r=>alert(r.result))
            
    }

    return<>
    
    <main style={{maxHeight:"600px"}}>

    <link rel="stylesheet" href="./css/login.css" />


    <div className="logo"><img src="./img/logo_med.png" alt="logo" /></div>
    <div className="title" style={{fontSize:"1.5em"}}>Borrar Usuario SOS</div>
    <div className="dialog" style={styleDialog}>

            Sus datos seran eliminados totalmente de nuestro sistema,
            escriba sus credenciales para proceder:

    </div>
    <form name="login" method="POST" className="login">
        <input type="text" name="user" className="form" id="" placeholder="Email"   autoComplete="off"/>

        <input type="password" className="form" placeholder="Contraseña" name="password"  id="" autoComplete="off"/>
        <input type="password" className="form" placeholder="Reescriba la Contraseña" name="password"  id="" autoComplete="off"/>
        <input type="button" className="submit" value="Borrar" id=" loginbtn" onClick={deleteSOSUser} />

    </form>

    </main>
    </>
}