import { ip } from "../../GlobalData"

export default function getRecorridoPetition() {
    const petition = [window.localStorage.getItem("credentials")]

    var resp = fetch(`${ip}/protected/getMainDataRouter`,{
        method:'POST',
        headers:{
            'Content-Type':"application/json"
            
        },
        body:JSON.stringify(petition)

    })


    return resp
}