import whyCooseStyle from "../css/WhyCoose.module.css"

export function WhyCoose() {
    

    return <div className={whyCooseStyle.why_Choose}>


        <div className={whyCooseStyle.title}>
            Por que elegirnos
        </div>

        <ul className={whyCooseStyle.points}>
            <li>Experiencia Comprobada: Años de experiencia respaldan nuestro compromiso con la excelencia.  </li>
            <li>Enfoque Personalizado: Trabajamos estrechamente contigo para entender tus metas y ofrecer soluciones a medida.  </li>
            <li>Innovación Constante: Mantenemos nuestro pulso en las últimas tendencias tecnológicas para asegurar que tu proyecto esté a la vanguardia.  </li>
            <li> Soporte Continuo: Nuestro compromiso no termina con la entrega. Estamos aquí para brindarte soporte y actualizaciones constantes.  </li>
            <li>Visión moderna y eficaz para la gestión de datos con nuestros servicios de excelencia.  </li>
        </ul>
        
        <div className={whyCooseStyle.drawable}/>

    </div>
}