import { ip } from "../GlobalData"
import { useState } from "react"
import LoadingSpinner from './Components/LoadingSpinner'
import { useEffect } from "react"


export const RegisterUser = ()=>{

    const [loading,setloading] = useState(true)

    function timming() { //Simulates a request; makes a "promise" that'll run for 2.5 seconds
        return new Promise(resolve => setTimeout(() => resolve(), 1500));
      } 


    useEffect(()=>{
       timming().then(()=>{
        setloading(false)
       })

    })

    return <>
    {
        loading ?<>
        
        <LoadingSpinner/>
        
        </>:""
    }
    <link rel="stylesheet" href="css/register_user.css" />

    <form name="register_user" className="register_user">
    <div className="logo"><img src="./img/logo_med.png" alt="logo" /></div>

        <div className="title">
            Registrar Empresa
        </div>
        <input type="text" name="user" className="form"  placeholder="Usuario"  />

        <input type="password" className="form" placeholder="Contraseña" name="passw"/>

        <input type="number" className="form" placeholder="Telefono" name="telefono"/>


        {/* <input type="file" name="logo" id="file_logo" accept=".png" /> */}

        <input type="button" className="submit" value="Entrar" id=" registrar" onClick={registrar} />


    </form>

    
    </>



}

const registrar = ()=>{


    var form = document.querySelector(".register_user")

    if(form.user.value == "") return alert("Campo usuario vacio")
    if(form.passw.value == "") return alert("Campo Contraseña vacio")
    if(form.telefono.value == "") return alert("Campo telefono vacio")


    var url = new URL(window.location.href);
    var token = url.searchParams.get("token");
    // var fileResult = ""
    // var file = form.logo.files[0]
    // var reader = new FileReader();
    // reader.readAsDataURL(file);
    // reader.onload = function () {
    //   fileResult = reader.result
    //   fileResult = fileResult.replace("data:image/png;base64,","");
      var petition = [token,
        form.user.value,
        form.passw.value,
        form.telefono.value,
        // fileResult
        ]

        fetch(`${ip}/web/registerUser`,{
            method:'POST',
            headers:{
                'Content-Type':"application/json"
                
            },
            body:JSON.stringify(petition)
    
        }).then(r=>r.json()).then(r=>{

            if(r[0] =="success") window.location.href = "/"

        })
    
    

    // };

    // reader.onerror = function (error) {
    //   console.log('Error: ', error);
    // };
    
}
