import styles from "../css/slider.module.css"
import styledAnimatedBackground from "../../Components/componentAnimatedBackground/styleBackgroundAnimated.module.css"
import { useEffect } from "react"
export function Slider() {

    const textContain = {
        "title":"Transforma tu Visión en Realidad con Nuestros Servicios Tecnológicos ",
        "text":[
            "¿Buscas soluciones informáticas y de tecnología aplicada a la seguridad que sean innovadoras y resultados excepcionales? ¡Estás en el lugar adecuado! En Gesnu Tech C.A, nos especializamos en ofrecer soluciones tecnológicas a medida que potencian tu negocio y llevan tus ideas al siguiente nivel.",

        ]
    }
    useEffect(()=>{


            setTimeout(() => {
                var element = document.querySelector("#slider_dialog").style
                element.opacity = "1"
                element.transform = "translateX(0)"


                const drawableElement = document.querySelector("#animated_circle").style
                drawableElement.opacity = "1"
                drawableElement.transform = "translateX(0)"
            }, 50);
            
            
                
    },[])
    return<>

        <div className={styles.slider} id="home">
            <div  className={styles.dialog} id="slider_dialog">

                <div className={styles.title}>
                   {textContain.title}
                </div>
                <div className={styles.textContain}>
                    {
                        textContain.text.map(r=>{
                            return <>
                            {r}
                            <p/>
                            </>
                        })
                    }
                </div>

            </div>
            <div className={styles.drawable} id="animated_circle"/>
                 
        <div className={styledAnimatedBackground.area} >
                    <ul className={styledAnimatedBackground.circles}>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                    </ul>
        </div >   
        </div>

    </>
    
}