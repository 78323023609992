
// export const ip = "https://www.marckygps.com/api"
export const ip = "https://www.marckygps.com/marckygpsv1/api"


// localip
// export const ip = "http://192.99.73.128:3450/api"
// export const ip = "https://g4gdbmk3-3030.use2.devtunnels.ms/api"



export const api_key_google_maps = "AIzaSyAjYMhcKLzvgnYihFDR7zRN11QWDCYIV9Q"

// v2.3
//Added Principal Page