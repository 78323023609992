import { ip } from "../../GlobalData"

export function SendNewPass(credentials) {
    return fetch(`${ip}/protected/set_new_password`,{
        method:'POST',
        headers:{
            'Content-Type':"application/json"
            
        },
        body:JSON.stringify(credentials)
    })
}