import { ip } from "../../GlobalData"

export default function FormUser(){

    const exit = ()=>{
        document.querySelector(".formUser").style.display = "none"

    }


    const submitform = ()=>{
        

            const response = UpdateUser(document.querySelector(".formUser input[name=\"user\"]").id).then(r=>r.json()).then(r=>{

                if(r[0] == "success"){
                    document.querySelector(".formUser").style.display = "none"
                    window.location.reload()
    
    
                }else{
                    alert("ups, ha ocurrido un error")
                }
    
    
            })
        

    }

    return <>
    <link rel="stylesheet" href="./css/formUser.css" />



        <form className='formUser' name='formUser'>
            <img src="./img/icon_close.png" onClick={exit} className='exit' />
            <div className="title">Crear Cliente</div>
            <input type="text" name="user" placeholder='Nombre' autocomplete="off" />
            <input type="password" name="passw" placeholder='Contraseña' autocomplete="off" />

            
            <div>
            <span>Limite de reportes</span>
            <input type="number" name="reportLimit"/>
            </div>
            <div>
            <span>Limite de Usuarios</span>
            <input type="number" name="userLimit"/><br/>
            </div>
            <div>
            <span>Limite de Sucursales</span>
            <input type="number" name="tableLimit"/>  
            </div> 
            <div>
            <span>Limite de QR</span>
            <input type="number" name="qrLimit"/>
            </div>
            <div>
            <span>Limite de Areas QR</span>
            <input type="number" name="qrAreaLimit"/>
            </div>
        
            <input type="button" className="submit" value="Entrar" onClick={(e)=>submitform()}/>

        </form>

    
    </>

}
const createUser = ()=>{

    const form = document.forms["formUser"]

    var petition = [window.localStorage.getItem("credentials"),
                    form.user.value,
                    form.passw.value,
                    "1",
                    form.userLimit.value,
                    form.qrLimit.value
                    ]
        

    var resp = fetch(`${ip}/protected/createUser`,{
        method:'POST',
        headers:{
            'Content-Type':"application/json"
            
        },
        body:JSON.stringify(petition)

    })

    return resp

}
const UpdateUser = (id)=>{

    const form = document.forms["formUser"]

    var petition = [window.localStorage.getItem("credentials"),
                    form.user.value,
                    form.passw.value,
                    "1",
                    form.userLimit.value,
                    form.qrLimit.value,
                    form.qrAreaLimit.value,
                    form.reportLimit.value,
                    form.tableLimit.value,
                    id
                    ]
        

    var resp = fetch(`${ip}/protected/UpdateUser`,{
        method:'POST',
        headers:{
            'Content-Type':"application/json"
            
        },
        body:JSON.stringify(petition)

    })

    return resp
}
