import { useState } from "react"
import { SetMap } from "./setMap"
import { ip } from "../../GlobalData"
import { Reporte } from "./ComponentReporte"
export function CardRecorrido({data,closeMap,sucursalName}){
    const idRecorrido = data[2]
    const [reporte,setReporte] = useState("")
    return<>
    <link rel="stylesheet" href="./css/card_recorrido.css" />
    <div className="card-recorrido">



        
    <header>
        <div className="exit" onClick={()=>closeMap()}><img src="./img/icon_close.png" alt="" /></div>
    </header>
    <SetMap Recorrido = {["","","","",data[0]]} setReporte={setReporte} CurrentGeocerca={[]}/>
    {reporte!=""?<Reporte  reporte={reporte} setReporte={setReporte} tableName={sucursalName} idRecorrido={idRecorrido}/>:""}
    </div>

    </>

}



