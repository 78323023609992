export function CentrodeAyuda(){

    return<>
    
    <link rel="stylesheet" href="./css/drawableSection.css" />
    <a href="https://sites.google.com/view/marckycentrodeayudas/inicio" className="link-stiled">
    <article className="drawable-component">
        <div className="drawable">
            <img src="./img/imgcentrodeayuda.jpg"/>
        </div>
        <div className="title">
            Accede a nuestro centro de ayuda MarckyGPS pulsando aqui
        </div>
    </article>
    </a>
    
    </>
}