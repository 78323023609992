
import { ip } from "../../GlobalData"
export function getReport(key, petition) { 

    
    return fetch(`${ip}/web/getReporte`,{
        method:'POST',
        headers:{
            'Content-Type':"application/json"
            
        },
        body:JSON.stringify([key,petition])

    })}
