import styles from "../css/plans_style.module.css"

export function Plans({ Information }) {
  return (
    <>
      <div className={styles.planes} id="plans">
        <section>
          <div className={styles.title}>Planes</div>

          {Information.Plans.map((r) => {
            return (
              <div className={styles.plan}>
                <div className={styles.name}>{r.title}</div>
                <div className={styles.cost}>{r.cost}</div>
                <ul className={styles.caracteristicas}>
                  {r.Characteristics.map((r) => {
                    return <li>{r}</li>;
                  })}
                </ul>
                    <a href="#">
                      <input type="button" value="Contactanos" className={styles.apply_plan} />
                    </a>

                  
              </div>
            );
          })}
        </section>
      </div>
    </>
  );
}