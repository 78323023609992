import { ip } from "../GlobalData"
import LoadingSpinner from './Components/LoadingSpinner'
export default function Principal() {

    comproveTypeUser()


    
    function comproveTypeUser(){


        var petition = [window.localStorage.getItem("credentials")]

        fetch(`${ip}/protected/comproveTypeUser`,{
            method:'POST',
            headers:{
                'Content-Type':"application/json"
                
            },
            body:JSON.stringify(petition)

        }).then(
            r=>r.json()


        ).then(r=> {
            
            switch (r[2]) {
                case 0:
                    window.location.href = "/admin"
                
                    break;

                case 1:
                    window.location.href = "/sucursales"

                    break;

                case 3:
                    window.location.href = "/reportes_supervisor"
                    break;


                default:
                    window.localStorage.setItem("credentials","")
                    window.location.href = "/"

                    break;
            }


        }).catch(r=> alert("error"))


    }



return <>
















    </>
}