import {delTablePetition} from "../api/delTablePetitionApi"
  export function DeleteSucursal({CardDelTable,setCardDelTable}) {



    return <>
        
    {
        CardDelTable !=""?<>
        
        
        <div className="Alert">
        <div className="title">Borrar Tabla</div>
        <div className="description">Desea borrar esta tabla?</div>
        <div className="condition">
        <div className="no" onClick={()=>setCardDelTable("")}>no</div>
        <div className="si" onClick={()=>delTable(CardDelTable)}>si</div>
        </div>

        </div>


        </>:""
    }

</>
    
  }

  const delTable = (e)=>{
    delTablePetition(e).then(r=>r.json()).then(r=>{

        if(r[0] === "success"){

            
            window.location.reload()



            }else{

            alert("error")
            window.location.reload()



            }
        }
    )
}