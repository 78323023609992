import { ip } from "../../GlobalData"
import { useState } from "react"
import { UserSelector, userSelector } from "./UserSelector"
export function TableConfig({userList,setUserList,tablename,Section}){

    const [CardUserSelector,setCardUserSelector] = useState(false)
    const [CardDeluser,setCardDeluser] = useState("")
    return <>
    <link rel="stylesheet" href="./css/table_config.css" />
    <div className="table_config">
        <header>
            <div className="spacing"></div>
            <div className="title">{tablename}</div>

            <div className="tools">
            <img src="./img/icon_arrow_left.png" className="buttom_back" onClick={hideTableConfig} />
            <div className="addUser" onClick={()=> userSelector(setCardUserSelector)}><img src="./img/icon_add.png"  /></div>
            </div>
        </header>

    <div className="list_clientes_header">
    <div className="id">ID</div>
    <div className="tableName">Nombre</div>
    <div className="borrar">borrar</div>
    </div>
    
    <ul className="list_clientes">
    {userList !== undefined ?userList.map((r,i)=><>
            <li key={r[0]} className={r[3]}>
            <div className="id">{i+1}</div>
            <div className="name">{r[3]}</div>
            <div className="options">
                <img src="./img/icon_del.png" id={r[0]} onClick={  ()=>setCardDeluser(r[3]) }/>
            </div>
        </li>
    
        </>):""}  
    </ul>
    

    {
        CardDeluser !=""?<>
        
        
        <div className="Alert">
        <div className="title">Borrar Usuario</div>
        <div className="description">Desea borrar este usuario?</div>
        <div className="condition">
        <div className="no" onClick={()=>setCardDeluser("")}>no</div>
        <div className="si" onClick={e=>{
            
            delUser(CardDeluser)
            var elements = document.querySelectorAll("ul.list_clientes li")
            elements.forEach(r=>{
                if(r.className == CardDeluser){
                    setUserList(
                        userList.filter(r=>r[3]!=CardDeluser)
                    )
                    setCardDeluser("")
                }
            })
        
        }
            
            }>si</div>
        </div>

        </div>


        </>:""
    }


    </div>

    

    {
        CardUserSelector ?  <UserSelector setCardUserSelector={setCardUserSelector} 
        CardUserSelector={CardUserSelector} 
        deluser={deluser}
        section={Section}/>:""
    }
    </>



}

const deluser = ()=>{
    const selector = document.querySelector("#user-selector").value
    
    var petition = [window.localStorage.getItem("credentials"),
                    selector
                    ]
    fetch(`${ip}/web/protected/delUser`,{
        method:'POST',
        headers:{
            'Content-Type':"application/json"
            
        },
        body:JSON.stringify(petition)

    }).then(r=>r.json()).then(r=>{
        
    })
}

const delUser = (id)=>{

    var petition = [window.localStorage.getItem("credentials"),
                    id
                    ]
        

    var resp = fetch(`${ip}/web/delUSerfromSucursalQr`,{
        method:'POST',
        headers:{
            'Content-Type':"application/json"
            
        },
        body:JSON.stringify(petition)

    }).then(r=>r.json()).then(r=>{
        return "1"
    })



}

const hideTableConfig = ()=>{

    document.querySelector(".table_config").style.left = "-100%"

}


const hideAlert = ()=>{

    document.querySelector(".table_config .Alert").style.display = "none"


}

