import { ip } from "../../GlobalData"

export function getSucursalData(credentials,sucursal) {

    const petition = [credentials,sucursal]

    var resp = fetch(`${ip}/web/getSucursalData`,{
        method:'POST',
        headers:{
            'Content-Type':"application/json"
            
        },
        body:JSON.stringify(petition)

    })


    return resp

    
}
export function getSucursalQRData(credentials,sucursal) {

    const petition = [credentials,sucursal]

    var resp = fetch(`${ip}/web/getQRSucursalData`,{
        method:'POST',
        headers:{
            'Content-Type':"application/json"
            
        },
        body:JSON.stringify(petition)

    })


    return resp

    
}