import { ip } from "../../GlobalData";
export  function createQRSucursalApi (coords){

    const SucursalName = document.querySelector(".form-table-name").value;

    var petition = [window.localStorage.getItem("credentials"),
        SucursalName,
        coords
    ]

    fetch(`${ip}/protected/AddSucursal`, {
        method: 'POST',
        headers: {
            'Content-Type': "application/json"
        },
        body: JSON.stringify(petition)
    }).then(r => {
        if (r.status === 501) {

            alert("Limite superado")
            return;
        }
        if (r.status === 201) r.json().then(r => {

            alert(r)
            return
        })



        r.json().then(r => {

            window.location.reload()

        })



    })


}
